
.grid-container svg g {
  fill: #000;
  fill-opacity: 1;
}
.grid-container svg g:hover {
  fill: #e2dfdf;
  fill-opacity: 0.7;
}
.grid-container svg g:hover text {
  fill-opacity: 1;
}

.grid-container svg g polygon {
  /* stroke: #000000;
  stroke-width: 0.2;
  transition: fill-opacity .5s; */
}
.grid-container svg g text {
  font-size: 0.22em;
  fill: white;
  fill-opacity: 0.7;
  transition: fill-opacity .5s;
}
.grid-container svg path {
  fill: none;
  stroke: #000000;
  stroke-width: 0.2em;
  stroke-opacity: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
}
