html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #ffc700; */
  font-family: 'itc-avant-garde-gothic-pro' !important;
  font-size: 16px;
}

@font-face {
  font-family: 'itc-avant-garde-gothic-pro';
  src: url('../src/fonts/avgardd_2-webfont.eot');
  src: url('../src/fonts/avgardd_2-webfont.eot?#iefix') format('embedded-opentype'),
       url('../src/fonts/avgardd_2-webfont.woff2') format('woff2'),
       url('../src/fonts/avgardd_2-webfont.woff') format('woff'),
       url('../src/fonts/avgardd_2-webfont.ttf') format('truetype'),
       url('../src/fonts/avgardd_2-webfont.svg#avantgarde_bk_btdemi') format('svg');
  /* font-weight: normal;
  font-style: normal; */

}

header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  pointer-events: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FFFDFC;
  /* height: 100px; */
}
header .earth-logo a{
  font-size: calc(2.2 * 1rem);
  font-family: 'itc-avant-garde-gothic-pro';
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.4em;
  color: #000;
  display: flex;
  height: 75px;
  width: 100px;
  color: #000;
  align-items: center;
  display: flex;
  overflow: hidden;
  justify-content: center;
}

header .earth-logo{
  position: relative;
}
header .earth-logo p {
  margin-top: -16px;
  display: flex;
}
header .earth-logo p .nameid{
  color: #0f5bfd;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
}


header .earth-logo a img{
  height: 110px;
  min-width: auto;
}


header ul li a{
  font-family: 'itc-avant-garde-gothic-pro';
  color: #000 !important;
  text-decoration: none !important;
  backface-visibility: hidden;
  padding: 0.1em 0;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .02em;
  text-transform: none;
  line-height: 1.8em;
  font-size: calc((1.1 - 1) * 1.2vh + 1rem)
}
header ul li a.active{
border-bottom: 1px solid #000;
}
header .nav-menu{
  display: flex;
  align-items: center;
}
header .nav-menu .profile-block{
 margin-left: 30px;
}



.theme-changer-box {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  position: absolute;
  right: 0;
  bottom: 20px;
  z-index: 6;
  display: none;
}
.connected-wallet .theme-changer-box {
display: block;
}


.share-btns.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
align-items: center;
justify-content: center;
display: inline-flex;
width: 100%;
}
header .share-button{
background: transparent;
border-radius: 50%;
height: 35px;
width: 35px;
min-width: auto;
margin: 0;
padding: 0;
overflow: hidden;
display: inline-flex;
align-items: center;
justify-content: center;
margin-left: 15px;
}
.earth p{
  font-size: 30px;
}
.dark-theme .earth p{
  color: #fff;
}
header .share-button:hover{
  background: #fff;
}
header .share-button img{
  width:50%;
}

.theme-changer {
 background: #000;
 padding: 2px;
border-radius: 5px;
color: #fff;
}
.theme-changer .theme-btns button {
border: 1px solid transparent;
display: inline-flex;
align-items: center;
justify-content: center;
padding: 15px 12px!important;
position: relative;
margin: 2px;
color: #fff;
background: #000;
}
.theme-changer .theme-btns button:hover {
  background: #333;
  color: #fff;
  border-radius: 5px;
}
.theme-changer .theme-btns button.selected {
background: #333;
color: #fff;
  border-radius: 5px;
}
/* .theme-changer button.selected svg path{
fill:#fff
} */
.theme-changer .theme-btns button span {
display: inline-flex;
align-items: center;
font-size: 13px;
justify-content: center;
}

.theme-changer .theme-btns button span i {
margin-right: 5px;
display: inline-flex;
}

.theme-changer .theme-btns button span i svg {
display: inline-flex;
align-items: center;
}

.theme-changer .theme-btns button:nth-child(1) {
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
}

.theme-changer .theme-btns button:nth-child(2) {
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
}

.primary-btn,.secondary-btn {
  display: flex;
}
.primary-btn button,
.primary-btn a,.secondary-btn button,
.secondary-btn a{
  padding: 1.2rem 2.004rem;
  font-size: calc(1 * 1rem);
  font-family: 'itc-avant-garde-gothic-pro';
  font-weight: 500;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  line-height: 1.2em;
  text-decoration: none;
}
.primary-btn button.MuiButton-contained{
  /* color: #ffc700; */
  color: #fff;
  background-color: #000;
}
.primary-btn a:hover,.primary-btn button.MuiButton-contained:hover{
opacity: .8;
background-color: #000;
}
.secondary-btn button.MuiButton-contained{
  color: #000;
  background-color: #ddd;
}
.secondary-btn a:hover,.secondary-btn button.MuiButton-contained:hover{
opacity: .8;
background-color: #ddd;
}
footer{
  padding: 5px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: #ffc700; */
}
footer.connected{
  justify-content: flex-start;
  flex-direction: row;
  /* position: static; */
  /* background-color: transparent; */
}
footer.connected.temp-socialicon{
  justify-content: center !important;
  flex-direction: column !important;
}
footer ul{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: none;
}
footer ul li{
  display: flex;
  align-items: center;
  justify-content: center;
}
footer.connected ul{
  display: flex
}
footer ul li a{
  cursor: pointer;
  position: relative;
  margin: 0 10px;
  font-size: 16px;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border-bottom: 1px solid #3f51b5;
}
footer ul li a span{
  display: flex;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.dark-theme footer ul li a{
  color: #fff;
}

footer{
  padding-bottom:10px;
  z-index: 4;
}
.social-link-block {
    z-index: 2;
    position: relative;
    display: flex;
}
footer p,footer p a{
  color: #000;
  display: flex;
  font-size: calc((1.1 - 1) * 1.2vw + 1rem);
  font-family: 'itc-avant-garde-gothic-pro';
  font-weight: 400;
  font-style: normal;
  letter-spacing: .02em;
  text-transform: none;
  line-height: 1.8em;
  font-size: 15px;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
}
footer p{
  margin: 10px 0;
  display: flex;
 
}
footer p a{
  display: flex;
  margin-left: 5px;
  border-bottom: 1px solid #000;
  text-decoration: none;
  color: #000;
}
footer p a:hover{
color: #000;
text-decoration: none;
}
footer .social-link-block a{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid #000;
  margin: 0 6px;
}
footer .social-link-block a svg{
  width: 20px;
  height:20px;
}
footer a.social-link{
  /* color: #000;
  font-size: 18px;
  padding-bottom: 2px;
  margin:20px  15px;
  border-bottom: 1px solid #000;
  text-decoration: none; */
}
.legends-block{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-bottom: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFDFC;
  transform-origin: bottom;
  transform: scaleY(0);
  transition: all 0.5s;
}
.legends-block.legends-open{
  transform: scaleY(1);
}
.dark-theme .legends-block{
  background-color: #000;
}
.legends-block figure{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.legends-block figure aside{
  display: flex;
  align-items: center;
  justify-content: center;
}
figure.Legendwrpper,figure.Legendwrpper aside{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
figure.Legendwrpper aside img{max-width: 33%;}
.dark-theme figure.Legendwrpper .lighttheme-imgs{
  display: none;
}
.dark-theme  figure.Legendwrpper aside.darktheme-imgs{
  display: flex;
}
 figure.Legendwrpper aside.darktheme-imgs{
  display: none;
}
.ledgent-close-icon{
  position: absolute;
  right: 20px;
  top: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.dark-theme .ledgent-close-icon svg{
  fill:#fff
}
.legends-block figure img{
  max-width: 33%;
}
.page-cont{
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  background-color: #FFFDFC;
  height: calc(100vh);
}
.page-cont:before{
  content: "";
  position: absolute;
  right: -10%;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.1;
  z-index: 0;
  background: url(../src/images/hex.png) no-repeat;
  background-size: 50%;
  background-position: center right;
   
}
section.page-cont.connected-wallet{
  background-color: transparent; 
    /* height: auto; */
}
section.page-cont.connected-wallet:before{
  background: transparent;
}
.atlas-block-content{
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* margin-top: 225px; */
  width: 80%;
}
.atlas-block-content h1{
  font-size: calc(6.5 * 1rem);
  line-height: calc(1.4 * (1 + (1 - 6.5)/25));
  font-family: 'itc-avant-garde-gothic-pro';
  font-weight: 700;
  margin-bottom: 1rem;
  color: #000;
}
.atlas-block-content p{
  color: #000;
  font-size: calc(1.5 * 1rem);
  margin-top: 1rem;
  margin-bottom: 2em;
}
.atlas-block-content .primary-btn{
  display: flex;
  justify-content: center;
}


.wallet-block {
display: flex;

margin-bottom: 20px;
}
.wallet-block li{
  display: flex;
 margin: 0 10px;
}
.wallet-block li button{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 35px !important;
  margin: 10px 0;
  color: #fff !important;
  text-decoration: none;
  border-radius: 15px;
  font-size: 1.2rem;
} 
.sub-head{
  font-family: 'itc-avant-garde-gothic-pro';
  font-size: 13px;
  margin: 10px 0;
}
.wallet-block li button .icon{
  margin-bottom: 10px;
}
.wallet-block li button svg{
  width: 120px;
  height: 120px;
}
.wallet-block li a.coinbase-wallet{
  background-image: linear-gradient(to right, #3471f5, #0f5bfd);
}
.wallet-block li a svg{
  width: 30px;
  height: 30px;
}


/* hexagon grid start*/
.LegendModel .MuiPaper-root{
  width: 90%;
  max-width: 90%;
}
.LegendModel .MuiPaper-root .MuiTypography-root{
  text-align: center;
  font-size: 24px;
  font-family: 'itc-avant-garde-gothic-pro' !important;
}
.dark-theme .MuiPaper-root{
  background-color: #000;
}
.dark-theme .MuiPaper-root .MuiTypography-root{
  color: #fff;
}
.modal-connect{
    display: none;
    position:absolute;
    top: 0;
    bottom: 0;

    background-size: 100%;
    z-index: 9999;
    width: 100%;
    min-height: 100vh;
    cursor: pointer;

}
.modal-connect.open{
    display:block;
}
body,html{
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
}

#scene{
    display: none;
    position: fixed;
    right: -15%;
    bottom: 0;
    top: 0;
    width: 100%;
    opacity: 0.1;
    z-index: 0;
    /* background: url(../src/images/hex.png) no-repeat; */
    background-size: 50%;
    background-position: right 100px;
}
#scene:after{
    content:"";
    /* background: url(../src/images/hex.png) no-repeat;
    background-size: 50%;
    background-position: right; */
}
#scene.close{
    display: none;
}

.container{
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.img-2{
    margin-left: 18%;
}

.img-3{
 margin-top: -3%;
 margin-left: 50%;
}

.img-4{
    margin-top: 7%;
    margin-left: 30%;
}

.ground{
    height: 300px;
    width: 200%;
    background: white;
    margin-top: 19%;
    margin-left: -100%
}

.img-5{
    margin-top: -17%;
    margin-left: 10%;
}

.header{
    display: flex;
    flex-wrap: nowrap;
    width: 90%;
    position: fixed;
    top:0;
    left: 0;
    right:0;
    margin: 0px auto;
    z-index: 99;
}

.logo{
    font-weight: bold;
    color:#AFC2ED;
    margin-top: 35px;
}

.menu{
    margin-left: auto;
    margin-top: 40px;
}

.menu a{
    color: black;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.menu a:hover{
    color: #AFC2ED;
}

.menu a:not(:last-child){
    margin-right: 30px;
}

.menu a.active{
    border-bottom: 2px solid #AFC2ED;
    padding-bottom: 5px;
}

.top-info{
    margin-top: 80px;
    width: 50%;
}

.title{
    font-size: 42px;
    font-weight: 900;
    line-height: 55px;
}

.info{
    font-size: 14px;
    color: #676262;
    line-height: 25px;
    margin-top: 30px;
}

.flex{
    display: flex;
    flex-wrap: nowrap;
}

button{
    cursor: pointer;
}

.prime-btn{
    width: 140px;
    height: 35px;
    color: white;
    border:none;
    font-weight: 600;
    box-shadow: none;
    background: #AFC2ED;
    border-radius: 999px;
    transition: background-color 0.3s ease;
}

.prime-btn:hover{
    background: #91a4d1;
}

.sec-btn{
    width: 140px;
    height: 35px;
    color: #AFC2ED;
    border:1px solid #AFC2ED;
    font-weight: 600;
    box-shadow: none;
    background: transparent;
    border-radius: 999px;
    transition: all 0.3s ease;
}

.sec-btn:hover{
    border-color: #91a4d1;
    color: #91a4d1;
}

.top-btns{
    margin-top: 30px;
}

.top-btns .sec-btn{
    margin-left: 15px;
}







*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}



/* Outer Hexagon */
.hexagon-grid-container{
  display: flex!important;
  flex-wrap: wrap;
  width: 60%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.hexagon {
  position: relative;
  width: 140px;
  height: 80.83px;
  background-color: #9fa0a2;
  margin: 44px 2px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  box-shadow: 0 0 30px #000;
    transform: scale(1.02);
    transition: 0s;
}
  .hexagon:hover {
    box-shadow: 0 0 30px #000;
    transform: scale(1.02);
    transition: 0s;
  }
  .hexagon:before,
  .hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 70px solid transparent;
    border-right: 70px solid transparent;
  }
  .hexagon:before {
    bottom: 100%;
    border-bottom: 40.41px solid #dcac04;
  }
  .hexagon:after {
    top: 100%;
    width: 0;
    border-top: 40.41px solid #dcac04;
  }


/* Inner Hexagon */
.hexagon-inner {
  position: relative;
  width: 136px;
  height: 78.52px;
  width: 140px;
  height: 81.52px;
  background-color:#dcac04;
  margin: 39.26px 0;
  display: flex;
  justify-content: center;
}
  .hexagon-inner:before,
  .hexagon-inner:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 68px solid transparent;
    border-right: 68px solid transparent;
    z-index: 1;
  }
  .hexagon-inner:before {
    bottom: 100%;
    /* border-bottom: 39.26px solid #ddd; */
  }
  .hexagon-inner:after {
    top: 100%;
    width: 0;
    /* border-top: 39.26px solid #ddd; */
  }
.mapwrapper{
  position: relative;
  z-index: 6;
}
.zoom-btn-wrapper{
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  z-index: 7;
  background: #000;
  border-radius: 5px;
}
.zoom-btn-wrapper button svg path{
  fill:#fff
}
.filterblock {
 position: absolute;
 top: 20px;
 left:20px ;
 z-index: 2;
}
.filterblock .MuiFormControl-root{
  /* min-width: 200px; */
}

.dark-theme .filterblock .MuiFormControl-root .MuiFormLabel-root{
  color: #fff;
}
.dark-theme .filterblock .MuiFormControl-root .MuiInputBase-root{
  color: #fff;
}
.dark-theme .filterblock .MuiFormControl-root fieldset{
  border-color: #fff;
}
.dark-theme .filterblock .MuiFormControl-root svg{
  fill: #fff;
}
.dark-theme .zoom-btn-wrapper{
  background-color: #1a2132;
}
/* Media queries */
@media (min-width: 1920px) {
  .hexagon-grid-container {
    width: 1600px;
  }
  .hexagon:nth-child(21n + 12) {
    margin-left: 74px;
  }
}
@media (max-width: 1919px) and (min-width: 1280px) {
  .hexagon-grid-container {
    width: 1100px;
  }
  .hexagon:nth-child(14n + 8) {
    margin-left: 74px;
  }
}
@media (max-width: 1279px) and (min-width: 1024px) {
  .hexagon-grid-container {
    width: 960px;
  }
  .hexagon:nth-child(12n + 7) {
    margin-left: 74px;
  }
}
@media (max-width: 1023px) and (min-width: 781px) {
  .hexagon-grid-container {
    width: 780px;
  }
  .hexagon:nth-child(9n + 6) {
    margin-left: 74px;
  }
}
@media (max-width: 780px) and (min-width: 640px) {
  .hexagon-grid-container {
    width: 640px;
  }
  .hexagon:nth-child(7n + 5) {
    margin-left: 74px;
  }
}
@media (max-width: 639px) {
  .hexagon-grid-container {
    width: 320px;
  }
  .hexagon:nth-child(3n + 3) {
    margin-left: 74px;
  }
}



.hexagon.hexagon-green .hexagon-inner {
    background-color: #dcac04!important;
}
.hexagon.hexagon-green:before {
    border-bottom: 40.41px solid #dcac04!important;
}
.hexagon.hexagon-green:after {
    border-top: 40.41px solid #dcac04!important;
}
/* hexagon grid end*/

/* mat modal start  */



/* model popupu */
.earth-model .MuiPaper-root {
 border-radius: 5px;
}
.earth-model .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
  /* background-color: rgba(0, 0, 0, 0.8); */
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.modal-open header,.modal-open .page-cont,.modal-open footer{
  filter: blur(8px);
}
.earth-model .MuiDialogContent-root{
  border-radius: 30px;
  padding: 30px;
}
.earth-model .MuiDialogContent-root .modal-body{
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.earth-model .MuiDialogContent-root h3{
  font-size: calc(2 * 1rem);
  color: #000;
  padding-bottom: 20px;
  font-family: 'itc-avant-garde-gothic-pro';
}
.earth-model .MuiDialogContent-root h4{
  font-size: 1rem;
  color: #000;
  padding-bottom: 20px;
  font-family: 'itc-avant-garde-gothic-pro';
}
.earth-model .MuiDialogContent-root h5{
  font-size: 1rem;
  color: #000;
  margin: 10px 0;
}
.earth-model .MuiDialogContent-root h5 a{
  color: #787777;
  
}
.earth-model .close-btn{
  color: #fff;
  font-size: 15px;
  padding: 0;
  width: 30px;
  height: 30px;
  min-width: 30px;
  background: black !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  /* transition: 0.7s; */
}
.LegendModel .MuiPaper-root .MuiTypography-root{

}
.LegendModel .MuiPaper-root .MuiTypography-root .close-btn{
  position: absolute;
  top:20px;
  right: 20px;
}
.earth-model .close-btn:hover{
  background: #ffc700 !important;
  color: #000;
  /* transition: 0.7s; */

}

.wallet-block li button.text-black{
  color: #000 !important;
  border: 1px solid #ffc700;
}
.profile-block button {
  padding: 8px !important;
  /* border: 0;
  border-radius: 0;
  background-color: #000; */
}
.profile-block button:hover{
  opacity: 1 !important;
}
.profile-block button p {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-block button p img{
  width: 40px;
  height: 45px;
  border-radius: 3px;
  margin-right: 10px;
}
.profile-block button p .profile-id{
  display: flex;
  align-items: center;
      width: 190px;
    overflow: hidden;
}
.profile-block button p .ethereum-num{
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 14px;
  color: #fff;
  font-family: 'itc-avant-garde-gothic-pro';
}

.profile-block button p .profile-name{
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 18px;
  color: #fff;
  font-family: 'itc-avant-garde-gothic-pro';
  text-align: left;
}
.profile-block button p .profile-name::before{
  position: absolute;
  content: '';
  left: 3px;
  top:5px;
  width: 10px;
  height:10px;
  border-radius: 50%;
  background-color: red;
}
.profile-block button p .profile-name.connected::before{
  background-color: green;
}
.profile-block button p .eth-icon-text{
  color: #fff;
}
.profile-block button p .eth-icon-text span{
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 30%;
  white-space: nowrap;
}
.profile-block button p .log-out{
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.profile-block button p .log-out svg{
  width: 35px;
    height: 35px;
    margin-right: -6px;
}
.profile-block button p .log-out svg path{
  fill:#fff;
}
.profile-block button p .eth-icon-text{
  display: flex;
  align-items: center;
  color: #ffc700;
}
.profile-block button p .eth-icon-text i{
  display: flex;
  align-items: center;
}
.profile-block button p .eth-icon-text i svg{
  width: 25px;
  height: auto;
  margin-left: -5px;
}
.connected-wallet .grid-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.connected-wallet .grid-container .grid{
  position: relative;
  width: calc(100vw);
  height: calc(100vh - 140px);
  margin-top: 25px;
}


@media(max-width:1600px){
  .primary-btn a{
    padding: 1rem 2rem;
    font-size: calc(1 * 1rem);
  }
  .atlas-block-content h1{
    font-size: calc(5.5 * 1rem);
  }
  .atlas-block-content p{
    font-size: calc(1.3 * 1rem);
  }
  footer a.social-link{
    font-size: 17px;
  }
}
@media(max-width:1366px){
  /* .primary-btn a{
    padding: 1rem 2rem;
    font-size: calc(1 * 1rem);
  } */
  .atlas-block-content h1{
    font-size: calc(5.2 * 1rem);
  }
  .atlas-block-content p{
    font-size: calc(1.2 * 1rem);
  }
  footer a.social-link{
    font-size: 16px;
  }
}

@media(max-width:1280px){
  .primary-btn a{
    padding: 1rem 1.5rem;
    font-size: calc(0.8 * 1rem);
  }
  
  .atlas-block-content h1{
    font-size: calc(5 * 1rem);
  }
  .atlas-block-content p{
    font-size: calc(1 * 1rem);
  }
  footer a.social-link{
    font-size: calc(1.8 * 1rem);
  }
  footer .social-link-block a{
    margin: 0 7px;
  }
  footer .social-link-block a svg {
    width: 24px;
    height: 24px;
}
 
}
@media(max-width:1200px){
  header .earth-logo a{
    height: 90px;
    width: 90px;
  }
  header .earth-logo a img{
    height: 110px;
    /* min-width: 157px; */
  }
  header{
    padding: 5px 15px;
  }
  header .earth-logo a {
    font-size: calc(1.5 * 1rem);
  }
  header .nav-menu .profile-block {
    margin-left: 30px;
  }
  .profile-block button p .ethereum-num{
    font-size: 13px;
  }
  .profile-block button p img {
    width: 35px;
    height: 35px;
  }
  .profile-block button p .log-out{
    margin-left: 5px;
    width: 28px;
  }
  header ul li a{
    font-size: 0.8rem;
  }
  footer a.social-link {
    font-size: 16px;
  }
  footer p {
    margin: 3px 0;
  }
  footer {
      /* margin: 2px 0; */
  }
  .theme-changer-box{
    bottom: 5px;
  }
  footer p, footer p a{
    font-size: 13px;
    line-height: 1.4;
  }
  
  .connected-wallet .grid-container .grid {
    height: calc(100vh - 170px);
  }
  figure.Legendwrpper aside img{
    max-width: 100%;
  }
  figure.Legendwrpper, figure.Legendwrpper aside {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
}
@media(max-width:1024px){
  header{
    /* padding: 5px 25px; */
  }
  header ul li a{
    font-size: 1rem;
  }
  .primary-btn a{
    padding: 1rem 1.5rem;
    font-size: calc(0.8 * 1rem);
  }
  .atlas-block-content h1{
    font-size: calc(4.5 * 1rem);
  }
  .atlas-block-content p{
    font-size: calc(1 * 1rem);
  }
  footer .social-link-block a{
    padding: 4px;
  }
  footer .social-link-block a svg{
    width: 20px;
    height: 20px;
  }
  header .earth-logo a {
    height: 70px;
    width: 70px;
}
header .earth-logo p span{
  font-size: 14px;
}
header .earth-logo a img {
  height: 105px;
  /* min-width: 130px; */
}
footer ul {
  /* margin-left: 30px;
  display: flex;
  align-items: unset;
  justify-content: unset;
  position: static;
  width: 100%;
  top: unset;
  left: unset;
  transform: unset;
  flex-wrap: wrap; */
}
footer ul li a{
  margin: 5px;
}

}
@media (max-width:992px) {
  header .earth-logo a {
    font-size: calc(1.6 * 1rem);
  }
  header ul li a{
    font-size: 1rem;
  }
  .primary-btn a{
    padding: 1rem 1.5rem;
    font-size: calc(0.8 * 1rem);
  }
  .atlas-block-content h1{
    font-size: calc(4.2 * 1rem);
  }
  .atlas-block-content p{
    font-size: calc(1 * 1rem);
  }
  footer .social-link-block a{
    margin: 0 5px;
    /* font-size: calc(1.3 * 1rem); */
  }
  .theme-changer-box {
    /* bottom: 50px; */
}
}
@media (max-width:767px) {
  header .earth-logo a {
    font-size: calc(1.5 * 1rem);
  }
  header ul li a{
    font-size: 0.8rem;
  }
  .primary-btn a{
    padding: 0.8rem 1.1rem;
    font-size: calc(0.7 * 1rem);
  }
  .atlas-block-content h1{
    font-size: calc(3 * 1rem);
    line-height: normal;
    margin-bottom: 0.6rem;
  }
  .atlas-block-content p{
    font-size: calc(1 * 1rem);
    margin-top: 10px;
    margin-bottom: 18px;
  }
  footer .social-link-block a{
    /* margin: 15px; */
    /* font-size: calc(1.1 * 1rem); */
  }
  .theme-changer .theme-btns button{
    padding: 6px 5px!important;
  }
  .theme-changer-box {
    /* bottom: 70px; */
}
.legends-block{
  height: 100vh;
  margin-bottom: 0;
  padding-bottom: 60px;
}
.legends-block figure img{max-width: 100%;}
.legends-block figure aside{
  flex-direction: column;
}
.legends-block figure{
  padding: 15px;
}
  
}
@media (max-width:600px) {
  .earth{
    display: none;
  }
  .primary-btn a{
    padding: 0.6rem 1rem;
    font-size: calc(0.7 * 1rem);
  }
  .atlas-block-content h1{
    font-size: calc(3.2 * 1rem);
    margin-bottom: 0.6rem;
  }
  .atlas-block-content p{
    font-size: calc(1 * 1rem);
  }
  footer a.social-link{
    /* margin: 15px; */
    /* font-size: calc(1.1 * 1rem); */
  }
  footer{
    align-items:center;
  }
  footer p{margin-left: 15px;}
  header .nav-menu .profile-block {
      margin-left: 10px;
  }
  .profile-block button p .profile-id{width: 160px;}
  .theme-changer-box {
      bottom: 60px;
  }
  header .earth-logo p span{
    font-size: 12px;
  }
  .profile-block button {
      padding: 4px 6px!important;
  }
}
@media (max-width:480px) {
  .profile-block button p .log-out svg {
    width: 28px;
    height: 28px;
  }
  .profile-block button p .eth-icon-text i svg {
    width: 19px;
  }
  .profile-block button p .profile-id {
    width: 130px;
}
  .profile-block button p img {
    width: 28px;
    height: 28px;
}
  .profile-block button p .profile-name,.profile-block button p .eth-icon-text{
    font-size: 10px;
  }
  header .earth-logo {
    width: 30%;
  }
}
@media (max-width:420px) {
    footer {
      align-items: flex-end;
        padding-bottom: 10px;
  }
  footer ul li a{
    font-size: 14px;
  }
  .theme-changer-box{
    left: 10px;
    right: auto;
  }
  .theme-changer .theme-btns button {
    padding: 2px 2px!important;
  }
  footer ul {
    margin-left: 60px;
  }
}
@media (max-width:360px) {
  header .earth-logo a {
    font-size: calc(1.2 * 1rem);
  }
  header .nav-menu .profile-block {
    margin-left: 10px;
}

.profile-block button {
  padding: 5px !important;
}
.profile-block button p .eth-icon-text i svg {
  width: 22px;
}
  header ul li a{
    font-size: 0.8rem;
  }
  .primary-btn a{
    padding: 0.6rem 0.6rem;
    font-size: calc(0.7 * 1rem);
  }
  .atlas-block-content h1{
    font-size: calc(3 * 1rem);
    margin-bottom: 0.6rem;
  }
  .atlas-block-content p{
    font-size: calc(1 * 1rem);
  }
  footer a.social-link{
   
    /* font-size: calc(1 * 1rem); */
  }
}

 /*.connected-wallet .grid-container{
  display: inline-flex;
    width: 100%;
    margin: 0 auto;
}*/
@media only screen and (min-device-width: 700px) and (max-device-width: 992px) and (max-device-height: 1024px)  {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (max-device-height: 1024px) {
  .connected-wallet .grid-container .grid {
      /* height: 100%; */
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (max-device-height: 600px) {
  .connected-wallet .grid-container .grid {
    height: calc(100vh - 135px);
}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (max-device-height: 420px) {
  .connected-wallet .grid-container .grid {
    height: calc(100vh - 235px);
}
}




      .svgLoader {
        animation: spin 2s linear infinite;
        margin: auto;
      }
     
      .divLoader {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 9999;
        /* background: #ffc700; */
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
      }
      .divLoader p{
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 20px;

      }
    
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
    
        100% {
          transform: rotate(360deg);
        }
      }
    
      @keyframes lds-dice {
    
        0% {
          animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
        }
    
        100% {
          animation-timing-function: linear;
          animation-timing-function: cubic-bezier(0.4, 0, 1, 1);

        }
      }
    
      .lds-dice .polygon1 {
        animation: lds-dice 2s linear infinite;
        animation-delay: -1.5s;
      }
    
      .lds-dice .polygon2 {
        animation: lds-dice 2s linear infinite;
        animation-delay: -1.5s;
      }
    
/* light Version Start */
section.page-cont.connected-wallet,header.connected, footer.connected {
    background-color: #FFFDFC;
}
.grid-container svg g polygon {
    /* stroke: #000!important; */
}
/* light version End */

/* light Version Start */
section.page-cont.connected-wallet,header.connected.dark, footer.connected.dark {
    background-color: #fff;
}
.dark .grid-container svg g polygon {
    stroke: #fff!important;
}
/* light version End */

@media (max-width: 768px) {

.page-cont:before{
    background-size: 100%;
    /* background-position: 190% 80px; */
  }
}
@media (max-width: 675px) {
  .wallet-block{
    flex-direction: column;
  }
  .page-cont:before{
    width: 100%;
    background-size: 100%;
    /* background-position: 110px 280px; */
  }
  .earth-model .MuiDialogContent-root h4{
    text-align: center;
    line-height: 22px;
    font-size: 16px;
  }
}

@media (max-width: 475px) {
  .earth-model .MuiDialogContent-root h3{
    width: 100%;
    font-size: 23px;
    line-height: 30px;
    text-align: center;
  }
  .earth-model .MuiDialogContent-root h4{
    font-size: 14px;
    padding-bottom: 20px;
    text-align: center;
  }
 
  footer a.social-link, footer p, footer p a{
    font-size: 13px;
  }
  
}


/* @media (max-width: 425px) {
  .connected-wallet .grid-container .grid{
    height: 300px;
  }
}
  @media (max-width: 375px) {
  .connected-wallet .grid-container .grid{
    height: 280px;
  }
}
@media (max-width: 325px) {
  .connected-wallet .grid-container .grid{
    height: 220px;
  }
} */

.dark-theme section.page-cont.connected-wallet,
.dark-theme footer{
  background-color: #091020;
}
.dark-theme header{
  background-color: #1a2132;
}
.dark-theme .theme-changer{
  background-color: #091020;
}
.dark-theme  .theme-changer .theme-btns button.selected{
  background-color: #1a2132;
}
.dark-theme .primary-btn button.MuiButton-contained{
  background-color: #091020;
}
.dark-theme footer a.social-link,
.dark-theme footer p a{
  color: #fff;
  border-bottom: 1px solid #fff;
}
.dark-theme footer p{
  color: #fff;
}
.dark-theme header .share-button{
  background: #091020

}
.dark-theme header .share-button img {
  filter: invert(1);
}
.dark-theme header .earth-logo a img{
  /* background-color: #fff; */
}
.earthlogoDark{
  opacity: 0;
  display: none;
}
.dark-theme .earthlogoDark{
  opacity: 1;
  display: block;
}
.dark-theme .earthlogolight{
  opacity: 0;
  display: none;

}
.dark-theme footer .social-link-block a{
  border-color: #fff;
}
.dark-theme footer .social-link-block a svg path{
  fill:#fff
}

/* Side bar  */
.sidebar{
  position: fixed;
  top:0;
  left:10px;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}
.sidebar ul{
  display: grid;
  margin-top: 30px;
  max-height: calc(100vh - 145px);
  overflow: auto;
  padding-right: 10px;
}
.sidebar ul{
  
}
.sidebar ul li{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  padding: 12px 5px;
  font-size: 14px;
  font-family: 'itc-avant-garde-gothic-pro';
  color: #000;
  transition: all 0.5s;
}

.sidebar ul li::after,.sidebar ul li::before{
  position: absolute;
  content: '';
  background-color: #000;
  transform: scaleY(0);
  transition: all 0.5s;
  top:0;
  right: 0;
 
}
.sidebar ul li .sidebarIcon{
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
}
.sidebar ul li .sidebarIcon img.selected{
  position: absolute;
  top:0;
  left: 0;
  opacity: 0;
}
.sidebar ul li.active .sidebarIcon img{
  /* opacity: 0; */
}
.sidebar ul li.active .sidebarIcon img.selected{
  /* opacity: 1 !important; */
}
.sidebar ul li .sidebarIcon svg path{
  fill: #676767;
}
.sidebar ul li.active::before,.sidebar ul li.active::after{
  transform: scaleY(1);
}
.sidebar ul li::before{
  top:0;
  height: 100%;
  width: 2px;
  display: none !important;
}
.sidebar ul li::after{
  top:50%;
  width: 0;
  right: 0px;
  margin-top: -10px;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid #000;
      border-bottom: 10px solid transparent;
      background-color: transparent;

}
.sidebar ul li img{
  max-width: 40px;
  margin-bottom: 10px;
}
.dark-theme .sidebar ul li{
  color: #fff;
}
.dark-theme .sidebar ul li img{
filter: invert(3);
}
.dark-theme .sidebar ul li::before,.dark-theme .sidebar ul li::after{
background-color: #FFFDFC;
}


.newLegendBlock{
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 50%;
  height: auto;
  z-index: 5;
  display: none;
  background-color: transparent;
  transition: all 0.5s;
  
}
.connected-wallet .newLegendBlock{
  display: block;
}
.newLegendBlock img{
  width: 100%;
  transform: scaleY(0);
  transition: all 0.5s;
  transform-origin: bottom;
  

}
.newLegendBlock.open img{
  transform: scaleY(1);
  background-color: #fff;
  display: block;
}
.newLegendBlock p{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.newLegendBlock p span{
  margin-left: 10px;
  transition: all 0.5s;
}
.newLegendBlock.open{
  z-index: 8;
}
.newLegendBlock.open p span{
  transform: rotate(180deg);
}

@media only screen and (max-width: 1919px) {
  .sidebar ul li img {
    max-width: 40px;
  }
  .newLegendBlock{
    /* width: 420px; */
  }
}
@media only screen and (max-width: 1599px) {
  .sidebar ul li{
    padding: 7px 5px;
    font-size: 12px;
  }
  .sidebar ul li img {
    max-width: 35px;
  }
  .newLegendBlock{
    /* width: 400px; */
  }
  .sidebar ul {
    margin-top: 15px;
    max-height: calc(100vh - 175px);
}

}
@media only screen and (max-width: 1459px) and (max-height:700px){
  .sidebar ul{
    /* margin-top: 35px; */
  }
}

@media only screen and (max-width: 1439px) {
  .sidebar ul li img {
    max-width: 30px;
  }
  .sidebar ul li{
    /* padding: 14px 0; */
  }
  .newLegendBlock{
    /* width: 380px; */
  }
}
@media only screen and (max-width: 1200px) {
  .sidebar{
    width: 80px;
  }
  .sidebar ul{
    max-height: calc(100vh - 170px);
}
.sidebar ul li{
  padding: 15px 5px;
}
.sidebar ul li img{
    width: 30px;
}
.newLegendBlock{
  /* width: 360px; */
  right: 15px;
}

}
@media only screen and (max-width: 1023px) {
  .sidebar{
    width: 100%;
    height: 60px;
    flex-direction: row;
    top: 80px;
    left: 0;
  }
  .sidebar ul{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 0;
    /* padding-bottom: 20px; */
    padding-right: 0;
  }
  .sidebar ul li{
    padding: 10px 20px;
  }
  .sidebar ul li img {
    width: 25px;
}
.sidebar ul li::before{
  width: 100%;
  height: 1px;
  top: auto;
  bottom: 0;
}
.sidebar ul li::after{
  right: calc(50% - 10px);
    margin-top: 0;
    top: auto;
    margin-left: 0px;
    bottom: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #000;

}
.newLegendBlock{
  /* width: 340px; */
}
}
@media only screen and (max-width: 991px) {
  .connected-wallet .newLegendBlock{
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
 
  .sidebar ul li{
    font-size: 12px;
    padding: 10px 15px;
    min-width: 75px;
  }
  .sidebar ul li img{
    width: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .zoom-btn-wrapper{
    top:80px;
  }
  .sidebar ul{
    justify-content: flex-start;
  }
  .sidebar ul li{
    padding: 10px 8px;
  }
  .sidebar ul li img{
    width: 18px;
  }
}